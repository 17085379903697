/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-11-13 16:45:33
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-12 13:37:30
 */

/**
     * ruleTypes: 规则类型
     * genRules： rule 生成函数
     * ctx： Vue实例
     */
import { genModels } from '@/plugins/widget/c-form/rules';
export var models = genModels(function (genRules, ctx) {return [
  {
    kname: 'coverAddress',
    itemProps: {
      label: '封面图片：'
      //rules: [genRules('require')]
    },
    component: {
      name: 'upload-image',
      props: {
        limit: 9 } } }];});





// form 默认值
export var formData = function formData() {return {};};